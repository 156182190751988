import { Box, Grid, Typography } from '@mui/material';

import { DateTime } from 'luxon';
import { Link } from 'react-router-dom';
import Products from './Products';

const event = process.env.REACT_APP_EVENT.substring(3);
const eventDate = process.env.REACT_APP_EVENT_DATE;

function Home() {
  return (
    <>
      <HeroImage />
      <Box
        sx={{
          px: { xs: 2, sm: 8, md: 12 },
          pt: 3,
          pb: 10,
        }}>
        <EventInfo shown={eventDate} />
        <Products hide={eventDate} />
      </Box>
    </>
  );
}
function HeroImage() {
  return (
    <Box
      sx={{
        height: 150,
        background:
          'url("https://dinfucemstorage.blob.core.windows.net/image/dinfucem/hero-background.png")',
        backgroundPosition: 'center',
        overflow: 'hidden',
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        img: {
          top: {
            xs: '-75px',
          },
          transform: {
            xs: 'translateX(-50%)',
          },
        },
      }}>
      <img
        style={{
          height: 400,
          position: 'absolute',
          top: -75,
          left: '50%',
        }}
        src="https://dinfucemstorage.blob.core.windows.net/image/dinfucem/hero-foreground.png"
        alt="event title"
      />
    </Box>
  );
}
function EventInfo({ shown }) {
  const date = DateTime.fromISO(shown).setZone('Asia/Taipei');
  if (DateTime.now() < date) return null;
  return (
    <Box sx={{ mb: 4 }}>
      <Box sx={{ border: '1px dashed salmon', borderRadius: 1, p: 2 }}>
        <Typography
          variant="h5"
          sx={{ color: 'salmon', p: 1, fontWeight: 700 }}>
          {event}法會網路報名已截止
        </Typography>
        <Typography variant="p" sx={{ color: 'rgb(130,130,130)', ml: 1 }}>
          ** 法會當日接受現場報名
        </Typography>

        <Box sx={{ marginBottom: 2, pl: 1, pt: 5 }}>
          <Link style={{ fontSize: 19 }} to="/event">
            {event}法會牌位查詢
          </Link>
          <p>
            ↑ 請點選上方連結輸入被超薦人（亡者）或陽上（家屬）姓名查詢牌位。
          </p>
        </Box>
        {/* <iframe
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen=""
        frameborder="0"
        height="315"
        src={
          //'https://www.youtube.com/embed/Nbd1FH3Hjlo?si=_yjmwu1kOf0Od9Zq'
          DateTime.now() > DateTime.fromISO(eventDate)
            ? 'https://www.youtube.com/embed/EsmmgGjXPmA?si=TTREtnRz26bQJGq9'
            : 'https://www.youtube.com/embed/2PDAre6aTD0?si=a9nDvIJIJleRG1eM'
        }
        title="YouTube video player"
        width="560"></iframe> */}
        <h3
          style={{
            color: 'salmon',
            marginTop: '50px',
            marginBottom: '10px',
            marginLeft: '10px',
          }}>
          {event}法會時間表
        </h3>
        {event === '新春' ? (
          <SpringSchedule />
        ) : event === '清明' ? (
          <SummerSchedule />
        ) : (
          <FallSchedule />
        )}
      </Box>
    </Box>
  );
}
function SpringSchedule() {
  return (
    <table
      style={{
        width: '400px',
        fontFamily: 'monospace',
        fontSize: 16,
      }}>
      <tbody>
        <tr style={{ height: '30px' }}>
          <td style={{ width: '120px' }}>08:30～09:10</td>
          <td style={{ width: '200px' }}>灑淨</td>
        </tr>
        <tr style={{ height: '30px' }}>
          <td>09:40～10:30</td>
          <td>金剛寶懺（上卷）</td>
        </tr>
        <tr style={{ height: '30px' }}>
          <td>11:00～12:00</td>
          <td>午供</td>
        </tr>
        <tr style={{ height: '30px' }}>
          <td>13:30～14:10</td>
          <td>金剛寶懺（中卷）</td>
        </tr>
        <tr style={{ height: '30px' }}>
          <td>14:40～15:20</td>
          <td>
            金剛寶懺（下卷）
            <br />
            總迴向
          </td>
        </tr>
      </tbody>
    </table>
  );
}
function SummerSchedule() {
  return (
    <Grid container>
      <Grid item>
        <table
          style={{
            width: '400px',
            fontFamily: 'monospace',
            fontSize: 16,
          }}>
          <tbody>
            <tr>
              <td>08:30～09:10</td>
              <td>早課</td>
            </tr>
            <tr>
              <td>09:40～10:30</td>
              <td>慈悲三昧水懺（上卷）</td>
            </tr>
            <tr>
              <td>11:00～12:00</td>
              <td>午供</td>
            </tr>
            <tr>
              <td>13:30～14:00</td>
              <td>慈悲三昧水懺（中卷）</td>
            </tr>
            <tr>
              <td>14:40～16:00</td>
              <td>慈悲三昧水懺（下卷）及迴向</td>
            </tr>
          </tbody>
        </table>
      </Grid>
      <Grid item>
        <table
          style={{
            width: '400px',
            fontFamily: 'monospace',
            fontSize: 16,
          }}>
          <tr>
            <td>08:30～09:20</td>
            <td>地藏經（上卷）</td>
          </tr>
          <tr>
            <td>09:50～10:30</td>
            <td>地藏經（中卷）</td>
          </tr>
          <tr>
            <td>10:50～11:40</td>
            <td>地藏經（下卷）</td>
          </tr>
          <tr>
            <td>13:00～15:50</td>
            <td>施放瑜伽焰口、迴向</td>
          </tr>
        </table>
      </Grid>
    </Grid>
  );
}
function FallSchedule() {
  return (
    <Grid container>
      <Grid item>
        <table
          style={{
            width: '400px',
            fontFamily: 'monospace',
            fontSize: 16,
          }}>
          <tbody>
            <tr>
              <td>08:30～09:10</td>
              <td>藥師經</td>
            </tr>
            <tr>
              <td>09:40～10:30</td>
              <td>地藏經（上卷）</td>
            </tr>
            <tr>
              <td>11:00～12:00</td>
              <td>午供</td>
            </tr>
            <tr>
              <td>13:30～14:00</td>
              <td>地藏經（中卷）</td>
            </tr>
            <tr>
              <td>14:40～16:00</td>
              <td>地藏經（下卷）及迴向</td>
            </tr>
          </tbody>
        </table>
      </Grid>
      <Grid item>
        <table
          style={{
            width: '400px',
            fontFamily: 'monospace',
            fontSize: 16,
          }}>
          <tr>
            <td>08:30～09:20</td>
            <td>慈悲藥師寶懺（上卷）</td>
          </tr>
          <tr>
            <td>09:50～10:30</td>
            <td>慈悲藥師寶懺（中卷）</td>
          </tr>
          <tr>
            <td>10:50～11:40</td>
            <td>慈悲藥師寶懺（下卷）</td>
          </tr>
          <tr>
            <td>13:00～15:50</td>
            <td>施放瑜伽焰口、迴向</td>
          </tr>
        </table>
      </Grid>
    </Grid>
  );
}

export default Home;
